
<template>
  <validation-observer
    ref="userForm"
  >
    <b-toast
      :visible="isFailed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="onSubmit">
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="รหัสสมาชิก"
        label-for="codeName"
      >
        <b-form-input
          id="codeName"
          v-model="playerData.codeName"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="`${$t('user.username')} `"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="playerData.username"
          placeholder="@username"
          readonly
        />
      </b-form-group>
     
      <validation-provider
        v-slot="{ errors }"
        name="phoneNumber"
        rules="min:10|regex:^([0-9]+)$"
      >
        <b-form-group
          :label="$t('user.phone')"
          label-for="phoneNumber"
          label-cols-sm="12"
          label-cols-lg="3"
        >
          <b-form-input
            id="phoneNumber"
            v-model="playerData.phoneNumber"
            v-mask="'##########'"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            aria-describedby="input-live-feedback"
            readonly
          />
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.link_af')"
        label-for="affiliateLink"
      >
        <b-form-input
          id="affiliateLink"
          v-model="playerData.affiliateLink"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('fields.remark')"
        label-for="remark"
      >
        <b-form-input
          id="remark"
          v-model="playerData.note"
        />
      </b-form-group>
      <div class="text-right">
        <b-button
          class="mr-2"
          :to="{ name: 'VIEW_PLAYERS'}"
          variant="light"
        >
          {{ $t('buttons.back') }}
        </b-button>
        <b-overlay
          :show="player.isUpdatingPlayer"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'
import { banks } from '@/libs/constants.js';

export default {
  data() {
    return {
      required,
      isFailed: false,

      thBanks: banks,
      playerData: {
        affiliateLink: '',
        codeName: '',
        note: '',
        username: '',
        phoneNumber: '',
      },
    }
  },
  computed: {
    ...mapState(['player']),
    ...mapGetters(['playerDetail']),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    playerProfile() {
      return this.playerDetail.profile
    }
  },
  watch: {
    playerProfile(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      if (this.playerProfile && this.playerProfile.username) {
        const data = JSON.parse(JSON.stringify(this.playerProfile))
        this.playerData = { ...data }
      } else {
        this.playerData = {
          affiliateLink: '',
          codeName: '',
          note: '',
          username: '',
          phoneNumber: '',
        }
      }
    },
    onSubmit() {
      this.$refs.userForm.validate().then( async (success) => {
        if (success) {
          this.isFailed = false
          this.updatePlayer({ playerId: this.playerId, data: this.playerData})
        } else {
          this.isFailed = true

        }
      })
    },
  },
}
</script>
